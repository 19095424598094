import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'

import MenuIcon from '../assets/menu-icon-black.png'
import ExitIcon from '../assets/cancel-icon.png'
import Layout from '../components/Layout'
import SectionTitle from '../components/SectionTitle'

const BlogWrapper = styled.div`
  max-width: 800px;
  margin: 60px auto 4rem auto;

  * {
    color: #313639;
  }

  @media screen and (max-width: 900px) {
    margin: 0;
    max-width: 100%;
    padding: 24px 24px 10% 24px;

    h1 {
      margin: 0;
      padding: 24px 0 12px 0;
      font-size: 26px;
      overflow-wrap: ;
    }
  }
`

const Wrapper = styled.div`
  background-color: ${props => props.bgColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: 100%;
  max-width: 100vw;
  width: 100%;
`

const MenuButton = styled.img`
  opacity: 0.6;
  position: absolute;
  top: 0;
  right: 0;
  margin: 36px 36px 0 0;
  height: 80px;
  width: 80px;

  :hover {
    opacity: 0.8 !important;
  }

  @media screen and (max-width: 900px) {
    margin: 22px 22px 0 0;
    height: 50px;
    width: 50px;
  }
`
const ExitButton = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  margin: 36px 36px 0 0;
  height: 80px;
  width: 80px;
  z-index: 1;

  @media screen and (max-width: 900px) {
    margin: 22px 22px 0 0;
    height: 60px;
    width: 60px;
  }
`

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuOpen: false,
    }
  }

  openMenu() {
    this.setState({
      isMenuOpen: true,
    })
  }

  closeMenu() {
    this.setState({
      isMenuOpen: false,
    })
  }

  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const siteDescription = post.excerpt
    return (
      <Layout>
        <SectionTitle text="Blog posts" />
        <BlogWrapper>
          <h1>{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <div style={{ textAlign: 'center', paddingTop: '36px' }}>
            <Link to="/blog">Read more posts</Link>
          </div>
        </BlogWrapper>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
